<template>
    <main class="main">
        <Sidebar />
        <div class="workspace">
            <Loader v-if="viewLoader" />
            <router-view />
        </div>
    </main>
</template>
<script>
import Sidebar from './Sidebar.vue'
import Loader from './Loader.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Dashboard',
    data(){
        return {
            viewLoader: false,
        }
    },
    components: {
        Sidebar,
        Loader
    },
    computed: {
        ...mapGetters({
            wspLoader: 'GetWspLoader'
        }),
    },
    watch: {
        wspLoader: function(val){
            this.viewLoader = val;
        }
    }
}
</script>