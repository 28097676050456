function Alert(obj){
    const message = obj.message;
    const errors = obj.errors;
    const alert = document.createElement('div');
    alert.classList.add('alert');
    alert.insertAdjacentHTML('afterbegin', `
        <p>${message}</p>
    `);
    if(errors){
        alert.classList.add('alert-danger');
        const errorList = document.createElement('ul');
        for(let key in errors){
            let values = errors[key];
            for(let value of values){
                let li = document.createElement('li');
                li.innerText = value;
                errorList.insertAdjacentElement('beforeend', li);
            }
        }
        alert.insertAdjacentElement('beforeend', errorList);
    }
    document.body.insertAdjacentElement('beforeend', alert);
    setTimeout(function(){
        alert.remove();
    }, 5000);
}

export default Alert;