<template>
    <div class="workspace__header">
        <div class="workspace__title">
            <h1><font-awesome-icon :icon="icon" /> {{ title }}</h1>
        </div>
        <div class="workspace__nav">
            <router-link v-for="link in navlinks" :to="link.route" :key="link.route">
                <button><font-awesome-icon :icon="link.icon" /> {{ link.title }}</button>
            </router-link>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'page-header',
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
        },
        navlinks: {
            type: Array
        }
    }
}
</script>