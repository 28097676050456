<template>
    <div class="login-form">
        <h2>Вход</h2>
        <input v-model="email" type="text" placeholder="Логин" autocomplete="off">
        <input v-model="password" type="password" placeholder="Пароль">
        <button @click="btnHandler">Войти</button>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'LoginForm',
    data(){
        return {
            email: null,
            password: null,
        }
    },
    methods: {
        ...mapActions({
            login: 'LoginAction'
        }),
        btnHandler(){
            const payload = {
                email: this.email,
                password: this.password
            }
            this.login(payload);
            // console.log(payload);
        }
    }
}
</script>
