<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__body">
                <div class="copyright">
                    &copy; 2021 Sergey Prijan
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'Footer'
}
</script>