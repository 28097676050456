import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('../views/users/Users.vue'),
        children: [
            {
                path: 'new',
                name: 'UserCreate',
                component: () => import('../views/users/UserCreate.vue')
            },
        ]
    },
    {
        path: '/users/:id',
        name: 'UserCard',
        component: () => import('../views/users/UserCard.vue')
    },
    {
        path: '/packs',
        name: 'Packs',
        component: () => import('../views/packs/Packs.vue'),
        children: [
            {
                path: 'new',
                name: 'PacksNew',
                component: () => import('../views/packs/PacksNew.vue')
            },
        ]
    },
    {
        path: '/packs/types',
        name: 'PacksTypes',
        component: () => import('../views/packs/PacksTypes.vue'),
        children: [
            {
                path: 'new',
                name: 'PacksTypesNew',
                component: () => import('../views/packs/PacksTypesNew.vue'),
            }
        ]
    },
    {
        path: '/packs/types/:id',
        name: 'PacksTypesEdit',
        component: () => import('../views/packs/PacksTypesEdit.vue'),
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../views/orders/Orders.vue'),
    },
    {
        path: '/orders/:id',
        name: 'Order',
        component: () => import('../views/orders/Order.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes
})

export default router
