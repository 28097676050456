import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'

import URL from './url'
import Alert from '../plugins/alert'

// console.log(JSON.stringify(URL, null, 2));

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loader: true,
        wspLoader: false,
        admin: null,
        users: null,
        PackTypes: null,
        orders: [],
    },
    mutations: {
        clearStore: (state) => {
            state.admin = null,
            state.users = null
        },
        setAdmin: (state, data) => state.admin = data,
        setLoader: (state, data) => state.loader = data,
        setWspLoader: (state, data) => state.wspLoader = data,
        setUsers: (state, data) => state.users = data,
        updateUser: (state, data) => {
            state.users.map(user => {
                if(user.id === data.user.id) {
                    user.name = data.user.name;
                    user.email = data.user.email;
                    user.level = data.user.level;
                }
            })
        },
        pushUser: (state, data) => state.users.push(data.user),
        deleteUser: (state, id) => {
            const newUsersArray = state.users.filter(user => user.id !== id);
            state.users = newUsersArray;
        },
        setPackTypes: (state, data) => state.PackTypes = data,
        pushPackType: (state, data) => state.PackTypes.push(data),
        updatePackType: (state, data) => {
            state.PackTypes.map(type => {
                if(type.id === data.id){
                   type.name = data.name 
                }
            });
        },
        setOrders: (state, data) => state.orders = data,
        removeOrder: (state, id) => state.orders = state.orders.filter(item => item.id != id)
    },
    actions: {
        async GetUserAction({commit}){
            const response = await fetch(URL.user, {
                method: 'GET',
                headers: URL.headers()
            });
            if(await response.ok){
                const data = await response.json();
                commit('setAdmin', data);
            }
            commit('setLoader', false);
        },
        async LoginAction({commit, dispatch}, payload){
            const response = await fetch(URL.login, {
                method: 'POST',
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": 'application/json',
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            if(response.ok){
                commit('setLoader', true);
                localStorage.setItem('PersonalAccessToken', 'Bearer ' + data.token);
                dispatch('GetUserAction');
            }else{
                Alert(data);
            }
        },
        async LogoutAction({commit, dispatch}){
            commit('setLoader', true);
            const response = await fetch(URL.logout, {
                method: 'GET',
                headers: URL.headers()
            });
            if(response.ok){
                localStorage.removeItem('PersonalAccessToken');
                commit('clearStore');
                dispatch('GetUserAction');
            }
        },
        async GetUsersAction({commit}){
            commit('setWspLoader', true);
            const response = await fetch(URL.users, {
                method: 'GET',
                headers: URL.headers()
            });
            const data = await response.json()
            commit('setUsers', data);
            commit('setWspLoader', false);
        },
        async UpdateUserAction({commit}, payload){
            const response = await fetch(URL.users + '/' + payload.id, {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: URL.headers()
            });
            const data = await response.json();
            if(response.ok) commit('updateUser', data);
            Alert(data);
        },
        async LogoutUserAction({commit}, id){
            const response = await fetch(URL.users + '/' + id + '/logout', {
                method: 'GET',
                headers: URL.headers()
            });
            const data = await response.json();
            Alert(data);
        },
        async RegisterAction({commit}, payload){
            const response = await fetch(URL.register, {
                method: 'POST',
                headers: URL.headers(),
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            // console.log(data);
            if(response.ok) commit('pushUser', await data);
            Alert(data);
        },
        async UserDeleteAction({commit}, id){
            const response = await fetch(URL.users + '/' + id, {
                method: 'DELETE',
                headers: URL.headers()
            });
            const data = await response.json();
            if(response.ok){
                commit('deleteUser', id);
                router.push({name:'Users'});
            }
            Alert(data);
        },
        async UserPasswordUpdateAction({commit}, payload){
            const response = await fetch(URL.users + '/' + payload.id + '/password', {
                method: 'PATCH',
                headers: URL.headers(),
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            Alert(data);
        },
        async GetPackTypesAction({commit}){
            commit('setWspLoader', true);
            const response = await fetch(URL.packsTypes, {
                method: 'GET',
                headers: URL.headers()
            });
            if(await response.ok){
                const data = await response.json();
                commit('setPackTypes', data.types);
            }
            commit('setWspLoader', false);
        },
        async PackTypeCreate({commit}, payload){
            const response = await fetch(URL.packsTypes, {
                method: 'POST',
                headers: URL.headers(),
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            if(await response.ok){
                commit('pushPackType', data.type);
                router.push({name:'PacksTypes'});
                Alert(data);
            }else{
                Alert(data);
            }
            commit('setLoader', false);
        },
        async PackTypeUpdate({commit}, payload){
            commit('setWspLoader', true);
            const response = await fetch(URL.packsTypes + '/' + payload.id, {
                method: 'PATCH',
                headers: URL.headers(),
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            if(await response.ok){
                commit('updatePackType', data.type);
                // router.push({name:'PacksTypes'});
                Alert(data);
            }else{
                Alert(data);
            }
            commit('setWspLoader', false);
        },
        async getOrders({commit}) {
            commit('setWspLoader', true);
            const response = await fetch(URL.orders, {
                method: 'GET',
                headers: URL.headers(),
            });
            const data = await response.json();
            commit('setOrders', data.orders.reverse())
            commit('setWspLoader', false);
        },
        async updateOrder({commit}, payload) {
            try {
                const response = await fetch(URL.orders + '/' + payload.id, {
                    method: 'PATCH',
                    headers: URL.headers(),
                    body: JSON.stringify({
                        status: payload.status
                    })
                })
                const ok = await response.ok
                const data = await response.json()
                if(ok) Alert(data)
            } catch (error) {
                console.log('Catch: ', error);
            }
        },
        async deleteOrder({commit}, id) {
            try {
                const response = await fetch(URL.orders + '/' + id, {
                    method: 'DELETE',
                    headers: URL.headers(),
                })
                const ok = await response.ok
                const data = await response.json()
                if(ok) Alert(data)
                commit('removeOrder', id)
            } catch (error) {
                console.log('Catch: ', error);
            }
        }
    },
    getters: {
        GetLoader: state => state.loader,
        GetWspLoader: state => state.wspLoader,
        GetAdmin: state => state.admin,
        GetUsers: state => state.users,
        GetPackTypes: state => state.PackTypes,
        GetOrders: state => state.orders,
    },
    modules: {
    }
})
