<template>
    <header class="header">
        <div class="container">
            <div class="header__body">
                <div class="header__logo">
                    SP <span>склад</span>
                </div>
                <div class="header__admin">
                    {{ admin.name }}
                    <button @click="logoutHandler" class="btn-logout">Выйти</button>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Header',
    methods: {
        ...mapActions({
            logout: 'LogoutAction'
        }),
        logoutHandler(){
            this.logout();
            this.$router.push('/');
        }  
    },
    computed: {
        ...mapGetters({
          admin: 'GetAdmin'
        })
    }
}
</script>

<style sass>
    .btn-logout {
        margin: 0 0 0 10px !important;
    }
</style>