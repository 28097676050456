<template>
    <div class="workspace-body">
      <h1><font-awesome-icon icon="chart-line" /> Панель управления</h1>
    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {
    }
}
</script>
