<template>
    <div class="sidebar">
        <ul>
            <li>
                <router-link to="/">
                    <font-awesome-icon icon="chart-line" />
                    Консоль
                </router-link>
            </li>
            <li>
                <router-link to="/users">
                    <font-awesome-icon icon="user-secret" />
                    Пользователи
                </router-link>
            </li>
            <li>
                <router-link to="/packs">
                    <font-awesome-icon icon="box" />
                    Упаковка
                </router-link>
            </li>
            <li>
                <router-link to="/orders">
                    <font-awesome-icon icon="box" />
                    Заказы
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'Sidebar'
}
</script>