import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/sass/app.sass'

import PageHeader from '@/components/PageHeader.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faChartLine, faPlus, faArrowLeft, faBox, faList, faTrash } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faChartLine, faPlus, faArrowLeft, faBox, faList, faTrash)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('page-header', PageHeader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
