// const domain = "http://localhost:8000";
const domain = "http://sladkiy-podarochek.ru";
const api = "/api/v1";
const admin = "/api/v12s";

const URL = {
    domain,
    user: domain + admin + "/user",
    login: domain + api + "/login",
    logout: domain + admin + "/logout",
    register: domain + admin + "/register",
    users: domain + admin + "/users",
    packsTypes: domain + admin + "/pack/types",
    test: domain + api + "/test",
    headers: function(){
        return {
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Authorization": localStorage.getItem('PersonalAccessToken')
        }
    },
    orders: domain + admin + "/orders",
}

export default URL