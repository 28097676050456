<template>
    <div id="app">
        <Loader v-if="loader" />
        <div v-else class="dashboard">
            <div v-if="admin" class="dashboard__body">
                <Header />
                <Dashboard />
                <Footer />
            </div>
            <div v-else class="dashboard__login">
                <LoginForm />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from './components/Loader.vue'
import LoginForm from './components/LoginForm.vue'
import Header from './components/Header.vue'
import Dashboard from './components/Dashboard.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    components: {
        Loader,
        LoginForm,
        Header,
        Footer,
        Dashboard
    },
    methods: {
        ...mapActions({
            getUser: 'GetUserAction',
        })
    },
    computed: {
        ...mapGetters({
            loader: 'GetLoader',
            admin: 'GetAdmin',
        })
    },
    async created(){
        this.getUser();
    }
}
</script>
